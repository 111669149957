
function SoftText (text) {
    this.text = text
    this.say = 0
    this.pos = {x: 0, y: 0}
}

SoftText.prototype = {

    constructor: SoftText,

    draw: function (context, object, wid, heg) {
        var p = this.pos
        if (p.z >= 1) return

        if (p.x < 0 || p.x > wid || p.y < 0 || p.y > heg) {
            return
        }

        var fontSize = 14
        var fontface = 'Arial'
        this.say++
        context.font = fontSize + 'px ' + fontface
        context.fillStyle = 'white'
        var metrics = context.measureText(object.text).width
        var px = this.pos.x - (metrics * 0.5)
        var py = this.pos.y + (fontSize * 0.5)
        context.lineWidth = 3
        this.text = object.text
        context.strokeText(object.text, px + fontSize, py - fontSize)
        context.fillText(object.text, px + fontSize, py - fontSize)
    },

    renderToPos: function (obj, hw, hh) {
        this.pos = toScreenPosition(obj.obj, obj.cam, hw, hh)
    }

}

function toScreenPosition (obj, camera, widthHalf, heightHalf) {
    var vector = new THREE.Vector3()
    vector.setFromMatrixPosition(obj.matrixWorld)
    vector.project(camera)
    vector.x = Math.round((vector.x + 1) * widthHalf)
    vector.y = Math.round((-vector.y + 1) * heightHalf)

    return {
        x: vector.x,
        y: vector.y,
        z: vector.z
    }
};

export {SoftText}
